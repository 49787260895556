/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import makelaar from 'img/snel_makelaar_1.svg'
import whiteChevronDown from 'img/snel_white_chevron_down.svg'

// Components
import { SecondHeader, Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown'
import { WhatsappShareButton } from 'react-share'
import parse from 'html-react-parser'

const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    width: 191px;
    height: 45px;
    z-index: 9;
    position: relative;
    transition: transform 0.25s ease;
    :hover {
      transform: scale(1.05);
    }
  }
`

const HuisOpgevenWrapper = styled.div`
  ${SecondHeader} {
    float: left;
    line-height: 1.41;
    letter-spacing: -0.29px;
  }
`

const HuisOpgevenContainer = styled.div`
  width: 100%;
  height: 184px;

  ::before {
    content: '';
    position: absolute;
    display: block;
    background-image: url(${makelaar});
    background-repeat: no-repeat;
    background-size: contain;
    height: 261px;
    width: 230px;
    left: -35px;
    bottom: -55px;
  }
`

const ToggleHeader = styled(CustomLink)`
  ${Paragraph} {
    color: ${(props) => props.theme.color.text.light};
    text-transform: initial !important;
  }

  pointer-events: none;
`

const Toggle = styled(Dropdown.Toggle)`
  background-color: ${(props) => props.theme.color.main} !important;
  border: 2px solid ${(props) => props.theme.color.light} !important;
  outline: none;
  box-shadow: none !important;
  width: 191px;
  height: 45px;
  padding: 0 17px;
  border-radius: 59px;

  ::after {
    content: none;
  }
`

const Menu = styled(Dropdown.Menu)`
  ${StyledCustomLink} {
    border-bottom: none !important;
  }

  :hover {
    ${StyledCustomLink} {
      border-bottom: none !important;
    }
  }

  ${Paragraph} {
    transition: transform 0.25s ease;

    :hover {
      transform: scale(1.05);
    }
  }
`

const Item = styled(Dropdown.Item)`
  padding: 0 !important;

  ${Paragraph} {
    padding: 0.25rem 1.5rem;
  }
`

const CustomButtonWrapper = styled.div``

const StyledRedModalLink = styled.div`
  cursor: pointer;

  ${Paragraph} {
    width: 191px;
    height: 45px;
    z-index: 9;
    position: relative;
    transition: transform 0.25s ease;
    border-radius: 59px;
    font-size: 14px;
  }
`

const ShareButtonAbstract = ({ className, platform, href, children }) => {
  const open = () => {
    window.open(
      href,
      'targetWindow',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=500,
      height=500`
    )
  }

  return (
    <button
      type="button"
      onClick={open}
      className={className}
      data-platform={platform}
    >
      {children}
    </button>
  )
}

const Share = ({ setOpen, data, className, location }) => {
  const {
    site: {
      siteMetadata: {
        // eslint-disable-next-line no-unused-vars
        url,
      },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          url
        }
      }
    }
  `)

  const shareList = [
    {
      name: 'Facebook',
      component: (
        <ShareButtonAbstract
          href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
          platform="facebook"
        >
          <Paragraph className="color-text-main font-size-18 py-2">
            Facebook
          </Paragraph>
        </ShareButtonAbstract>
      ),
    },
    {
      name: 'Twitter',
      component: (
        <ShareButtonAbstract
          href={`https://twitter.com/intent/tweet/?text=${parse(
            data.title
          )}&url=${location.href}`}
          platform="twitter"
        >
          <Paragraph className="color-text-main font-size-18 py-2">
            Twitter
          </Paragraph>
        </ShareButtonAbstract>
      ),
    },
    {
      name: 'Linkedin',
      component: (
        <ShareButtonAbstract
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href
            }&title=${parse(data.title)}&summary=Mooi%20artikel!&amp;source=${location.href
            }`}
          platform="twitter"
        >
          <Paragraph className="color-text-main font-size-18 py-2">
            LinkedIn
          </Paragraph>
        </ShareButtonAbstract>
      ),
    },
    {
      name: 'Whatsapp',
      component: (
        <WhatsappShareButton
          url={location.href}
          title={parse(data.title)}
          separator=" | "
        >
          <Paragraph className="color-text-main font-size-18 py-2">
            Whatsapp
          </Paragraph>
        </WhatsappShareButton>
      ),
    },
  ]

  const share = () => {
    if (navigator.share) {
      // Web Share API is supported
      navigator
        .share({
          title: 'Snel ERA Makelaar woning',
          url: location.href,
        })
        .then(() => {
          // eslint-disable-next-line no-console
        })
        // eslint-disable-next-line no-console
        .catch(console.error)
    } else {
      // Fallback if not supported
    }
  }

  return (
    <HuisOpgevenWrapper className={`d-flex ${className ? `${className}` : ``}`}>
      <HuisOpgevenContainer className="position-relative color-background-main">
        <div className="d-flex align-items-center flex-wrap justify-content-end h-100 pr-4 py-4">
          {data.recapWoning.status === 'BESCHIKBAAR' && (
            <>
              <CustomButtonWrapper className="d-flex">
                <StyledRedModalLink onClick={() => setOpen(true)}>
                  <Paragraph className="d-flex justify-content-center align-items-center float-right color-background-striking color-text-light font-size-sm">
                    Plan een bezichtiging
                  </Paragraph>
                </StyledRedModalLink>
              </CustomButtonWrapper>
            </>
          )}
          {data.recapWoning.status === 'ONDER_BOD' && (
            <>
              <CustomButtonWrapper className="d-flex">
                <StyledRedModalLink onClick={() => setOpen(true)}>
                  <Paragraph className="d-flex justify-content-center align-items-center float-right color-background-striking color-text-light font-size-m">
                    Plan een bezichtiging
                  </Paragraph>
                </StyledRedModalLink>
              </CustomButtonWrapper>
            </>
          )}
          <Dropdown
            onToggle={share}
            className="w-100 d-flex justify-content-end"
          >
            <Toggle className="d-flex justify-content-between align-items-center">
              <ToggleHeader className="d-flex w-100 justify-content-between align-items-center">
                <Paragraph className="color-text-main d-flex align-items-center font-size-18 text-uppercase">
                  Delen
                </Paragraph>
                <img className="pl-2" src={whiteChevronDown} alt="" />
              </ToggleHeader>
            </Toggle>
            <Menu>
              {shareList.map((subItem, subIndex) => (
                <Item as="div" key={subIndex}>
                  {subItem.component ? (
                    subItem.component
                  ) : (
                    <StyledCustomLink to={subItem.url}>
                      <Paragraph className="color-text-main font-size-18">
                        {subItem.name}
                      </Paragraph>
                    </StyledCustomLink>
                  )}
                </Item>
              ))}
            </Menu>
          </Dropdown>
        </div>
      </HuisOpgevenContainer>
    </HuisOpgevenWrapper>
  )
}

export default Share
